@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

.login-bg {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  font-family: "Inter", sans-serif;
  overflow-y: auto;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

.login-logo-parent {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.login-logo {
  height: 100px;
  width: 100px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.logo-header-parent {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.logo-header {
  color: #111827;
  margin-bottom: 10px;
  margin-top: 15px;
  text-transform: capitalize;
  font-weight: 700;
  display: inline-block;
  float: none;
  font-family: "Inter", sans-serif;
}

.login-body-parent {
  float: left;
  width: 60%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20%;
  margin-right: 20%;
  overflow-y: auto;
  padding-bottom: 60px;
}

.login-input {
  padding: 12px 15px;
  background: #ffffff;
  color: black;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  margin-bottom: 10px;
  border-radius: 6px;
  height: 50px;
}

.b-r-6-up {
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0;
}

.b-r-6-down {
  border-radius: 0px 0px 6px 6px;
}

.remember-forgot-section {
  float: left;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.remember-me-parent {
  width: 50%;
  float: left;
}

.remember-me-checkbox {
  float: left;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  margin-top: 4px;
  border: 1px solid #d1d5db;
}

.remember-me-text {
  float: left;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.forgot-pwd-parent {
  width: 50%;
  float: right;
}

.forgot-pwd-text {
  color: #700cc2;
  letter-spacing: 0px;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 3px;
}

.login-buttons-parent {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.login-button {
  background: #700cc2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 0;
  height: 40px;
  cursor: pointer;
}

.gum-button {
  background: #d664ff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 0;
  height: 40px;
}

.phantom-button {
  background: #5339cc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 0;
  height: 40px;
}

.or-text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
