@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

.shoppyn-admin-bg {
  float: left;
  width: 100%;
  background: #fff;
  height: 100vh;
  overflow-y: auto;
  font-family: "Inter", sans-serif;
}

.Polaris-Navigation {
  display: inline-block;
}

.Polaris-Frame__Content {
  display: flex !important;
}

.user-popup-menu {
  background-color: white;
  border-radius: 0px 0px 6px 6px;
  padding: 10px;
  display: inline-block;
  border: 1px solid lightgray;
  width: 120px;
  margin-top: 10px;
  box-shadow: 0 1pt 6pt rgb(0 0 0 / 15%);
}

.pl-4-i {
  padding-left: 1rem !important;
}

.active-menu > a {
  background-color: #000000 !important;
  color: white !important;
}

.main-content {
  display: inline-block;
  width: calc(100% - 210px);
  margin-left: 15px;
  padding-top: 15px;
}

.Polaris-Navigation {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 200px !important;
}

.Polaris-Spinner {
  display: inline-block;
}

.black-bg {
  float: left;
  width: 100%;
  background: #000;
  height: 100vh;
}

.logo-loader-parent {
  float: left;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.pending-waves-parent {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px 20px;
  background: #371752;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  box-sizing: border-box;
}

.pending-wave-header {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
}

.pending-wave-time {
  float: right;
  color: #ff829b;
}

.pending-wave-parent {
  float: left;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pending-wave-profile {
  width: 60%;
  float: left;
}

.pending-wave-icon {
  height: 40px;
  width: 40px;
  float: left;
  margin-right: 10px;
}

.pending-wave-name-box {
  float: left;
  width: calc(100% - 50px);
}

.pending-wave-user-name {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 5px;
}

.sidebar-width {
  max-width: 200px;
}

.pending-wave-user-role {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: normal;
}

.pending-wave-people {
  float: left;
  width: 10%;
  text-align: center;
}

.pending-people-text {
  float: left;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: normal;
  margin-top: 0px;
}

.pending-people-price {
  float: right;
  width: 100%;
  text-align: right;
  color: white;
  font-weight: normal;
  margin-top: 0px;
}

.pending-people-usd-price {
  float: right;
  width: 100%;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  font-weight: normal;
  margin-top: 5px;
  font-size: 13px;
}

.pending-wave-price-parent {
  float: left;
  width: 30%;
  text-align: center;
}

.past-waves-parent {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  float: left;
  background: #fff;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  border-radius: 6px;
}

.past-waves-header {
  background: #f9fafb;
  padding: 12px 20px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  box-sizing: border-box;
}

.past-waves-header-text {
  font-family: "Inter", sans-serif;
  float: left;
  width: 100%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #6b7280;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}

.past-wave-parent {
  float: left;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 20px;
  box-sizing: border-box;
}

.past-wave-profile {
  width: 60%;
  float: left;
}

.past-wave-icon {
  height: 40px;
  width: 40px;
  float: left;
  margin-right: 10px;
}

.past-wave-name-box {
  float: left;
  width: calc(100% - 50px);
}

.past-wave-user-role {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: #6b7280;
  font-size: 12px;
  font-weight: normal;
}

.past-wave-user-name {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0px;
}

.past-wave-user-price {
  float: right;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: right;
}

.past-wave-amount-text {
  float: right;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: #6b7280;
  font-size: 12px;
  font-weight: normal;
  text-align: right;
}

.past-wave-people {
  float: left;
  width: 10%;
  text-align: center;
}

.past-people-text {
  float: left;
  width: 100%;
  text-align: center;
  color: black;
  font-weight: normal;
  margin-top: 0px;
}

.past-wave-price-parent {
  float: left;
  width: 30%;
  text-align: center;
}

.new-payment-parent {
  position: absolute;
  left: 4%;
  right: 4%;
  bottom: 15px;
}

.new-payment-button {
  background: #700cc2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 0;
  height: 50px;
  font-family: "Inter", sans-serif;
}

.b-a-inp {
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 36px;
  margin-top: 5px;
  box-sizing: border-box;
}

.display-ruby {
  display: ruby;
}
