@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

body {
  background: #fff;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: black;
  overflow-y: hidden;
}

@font-face {
  font-family: "Ubuntu Mono", monospace;
  src: url("../fonts/jackarmstrong.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #555;
  --color-link-hover: #98fadf;
  --color-info: #f7cfb9;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --time-anim: 3s;
  --delay-anim: 0.4s;
  --blend-mode-1: none;
  --blend-mode-2: overlay;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: #red;
  --blend-color-3: blue;
  --blend-color-4: transparent;
  --blend-color-5: #green;
}

#root {
  /* background-color: black; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cheeseburger-menu-overlay {
  z-index: 999999 !important;
}

.w-33p {
  width: 33.33%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Toastify__toast-container--top-center {
  top: 11em !important;
  width: 400px !important;
}

.strikethrough {
  text-decoration: line-through;
}

.Toastify__close-button {
  display: none !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}

.w-200 {
  width: 200px;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.6;
}

.user-profile-box {
  float: right;
  margin-right: 15px;
  height: 40px;
  width: 40px;
  margin-top: 45px;
}

.user-profile-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
}

.user-menu-parent {
  position: absolute;
  top: 95px;
  right: 50px;
  max-width: 300px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1pt 6pt rgb(0 0 0 / 15%);
}

.user-menu-parent ul {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.user-menu-parent > ul > li {
  float: left;
  width: 100%;
  padding: 15px 20px;
  line-height: 24px;
  vertical-align: middle;
  text-align: left;
  font-size: 16px;
  color: black;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
  font-family: "Ubuntu Mono", monospace;
}

.user-menu-parent > ul > li:last-child {
  border-bottom: 0 !important;
}

@media screen and (max-width: 1000px) and (max-height: 420px) {
  .Toastify__toast-container--top-center {
    top: 2em !important;
  }
}

@media screen and (max-width: 480px) {
  .mobile-div {
    display: none;
  }

  .desktop-div {
    display: block;
  }
}

.m-t-5 {
  margin-top: 5px;
}

.Toastify__close-button--light {
  display: bloack !important;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  background: white !important;
  top: 2em !important;
}

.pulse-animation-logo {
  margin-top: 40vh;
  margin-bottom: 40vh;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 480px) {
  .user-profile-box {
    margin-right: 0 !important;
    height: 55px !important;
    width: 55px !important;
    margin-top: 25px !important;
    display: inline-block;
    float: none;
  }

  .b-r-right {
    border-radius: 0 6px 6px 0;
  }

  .b-r-left {
    border-radius: 6px 0 0 6px;
  }

  .mobile-div {
    display: block;
  }

  .desktop-div {
    display: none;
  }
}

.root {
  float: left;
  width: 100%;
  overflow-y: hidden;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.dot1 {
  animation: visibility 3s linear infinite;
}

@keyframes visibility {
  0% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 3s linear infinite;
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }

  21% {
    opacity: 0;
  }

  22% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 3s linear infinite;
}

@keyframes visibility3 {
  0% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.pointer {
  cursor: pointer;
}

@keyframes blinking {
  0% {
    opacity: 0.25;
  }

  70%,
  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  100% {
    opacity: 0;
    filter: blur(0);
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes flicker {
  50% {
    color: white;
    filter: saturate(200%) hue-rotate(20deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pull-left {
  float: left;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.m-b-0-i {
  margin-bottom: 0px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-3 {
  margin-top: 2px;
}

.m-b-3 {
  margin-bottom: 2px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.m-t-30 {
  margin-top: 30px;
}

.full-height {
  height: 100%;
}

.flip {
  opacity: 1 !important;
  transform: rotateY(360deg);
}

.p-l-10-i {
  padding-left: 10px !important;
}

.courier {
  font-family: "Courier New", Courier, monospace;
}

.no-float {
  float: none !important;
}

.inline-block {
  display: inline-block;
}

.text-right-i {
  text-align: right !important;
}

.text-center-i {
  text-align: center !important;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-0 {
  margin-top: 0;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  list-style: none;
  margin-top: 15px;
}

.pagination > li:first-child > a:focus,
.pagination > li:first-child > a:hover,
.pagination > li:first-child > span:focus,
.pagination > li:first-child > span:hover {
  background: white !important;
}

.pagination > li:last-child > a:focus,
.pagination > li:last-child > a:hover,
.pagination > li:last-child > span:focus,
.pagination > li:last-child > span:hover {
  background: white !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 0;
}

.page-item:last-child .page-link {
  border: 0;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item.selected .page-link {
  color: white;
  pointer-events: none;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px !important;
  display: inline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.pagination > li {
  display: inline;
}

.pull-right {
  float: right;
}