.dashboard-home {
  display: inline-block;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100vh;
  box-sizing: border-box;
}

.Polaris-Modal-Section .Polaris-Box {
  padding: 0 !important;
}

.vendor-left-section {
  float: left;
  width: calc(65% - 15px);
}

.discounts-right-section {
  float: left;
  width: 35%;
  margin-left: 15px;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.no-polaris-header .Polaris-LegacyStack__Item {
  margin-top: 0 !important;
}

.dotted-underline {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

.form-control {
  padding: 10px 15px;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.right-vendor-icon {
  position: absolute;
  top: 20px;
  right: 25px;
}

.discounts-home {
  display: inline-block;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100vh;
  box-sizing: border-box;
}

.smaller-dropdown select {
  height: 30px;
  border: 0 !important;
}

.Polaris-Button--primary {
  background-color: var(--p-color-bg-primary) !important;
}

.delete-vendor-modal {
  position: absolute;
  left: 33.33%;
  width: 33.33%;
  top: 20vh;
  padding: 12px 15px;
  background-color: white;
  /* padding: 20px 25px; */
  border-radius: 12px;
}

.profile-form input:disabled {
  opacity: 1 !important;
  background: #f9f9f9 !important;
  border: 1px solid lightgray;
  border-radius: 4px;
  color: black !important;
  -webkit-text-fill-color: black !important;
}

.grid {
  display: grid;
}

.text-green {
  color: #00a650;
  font-weight: bold;
}

.text-red {
  color: #dd4b39;
  font-weight: bold;
}

.text-blue {
  color: #00a650;
  font-weight: bold;
}

.parent-div {
  float: left;
  width: 100%;
  text-align: center;
}

.parent-div p {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
